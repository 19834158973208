<template>
  <div class="nav-bar">
      <NavBar />
  </div>
  <div id="app">
    <router-view>
    </router-view>
  </div>

</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Koulen:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
  display: block;
  background: #e9e9e9;
  overflow: hidden;
}

#app{
  margin-top: 60px;
  height: calc(100vh - 60px);
}

.nav-bar {
  z-index: 9999;
}
:root {
  /* fonts */
  --font-koulen: Koulen;

  /* font sizes */
  --font-size-xl: 20px;

  /* Colors */
  --color-black: #000;

  /* Gaps */
  --gap-17xl: 36px;
  }
  router-view {
    margin-top: 100px;
  }
/* Hide scrollbar for all elements */
/* ::-webkit-scrollbar {
  display: none;
} */
/* navbar style */

/* Style for nav links when hovered */
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: rgb(255, 152, 170);
}

/* Style for nav links when active */
a.router-link-active.router-link-exact-active {
  color: rgb(211, 118, 134);
  text-decoration:none;
}

</style>