<template>
  <div class="nav-bar">
    <div class="nav-left">
      <img class="nav-logo" alt="logo" src="../assets/logo.svg" />
      <div class="nav-dropdown" @mouseleave="showDropdown=false">
        <button @click="toggleDropdown" @mouseenter="showDropdown=true">
          <img class='menu-icon' src="@/assets/menu.svg" alt="menu-icon" /></button>
        <div v-if="showDropdown" class="dropdown">
          <div class="nav-list-dropdown">
            <div class="nav-content" @click="handleClick('/works')"><router-link to="/works" class="{{ $style.navLink }}">Projects</router-link></div>
            <div class="nav-content" @click="handleClick('/archive')"><router-link to="/archive" class="{{ $style.navLink }}">Archive</router-link></div>
            <div class="nav-content" @click="handleClick('/about')"><router-link to="/about" class="{{ $style.navLink }}">About</router-link></div>
          </div>
        </div>
      </div>
      <div class="nav-title"><router-link to="/" class="{{ $style.navLink }}">Lidi Fu</router-link></div>
    </div>
    <div class="nav-list">
      <div class="nav-content" @click="handleClick('/works')"><router-link to="/works" class="{{ $style.navLink }}">Projects</router-link></div>
      <div class="nav-content" @click="handleClick('/archive')"><router-link to="/archive" class="{{ $style.navLink }}">Archive</router-link></div>
      <div class="nav-content" @click="handleClick('/about')"><router-link to="/about" class="{{ $style.navLink }}">About</router-link></div>
    </div>
    <div class="nav-right">
      <a href="mailto:lidi.fu@icloud.com">
        <img class="nav-icon" src="@/assets/email.svg" alt="email-icon"/>
      </a>
      <a href="https://www.instagram.com/lidi_fu" target="_blank" rel="noopener noreferrer">
        <img class="ins-icon" src="@/assets/ins.svg" alt="ins-icon" />
      </a>
      <a href="https://www.linkedin.com/in/lidi-fu" target="_blank" rel="noopener noreferrer">
        <img class="ins-icon" src="@/assets/linkedin.svg" alt="linkedin-icon" />
      </a>
    </div>
  </div>
</template>

<script>


export default {
  data(){
    return {
      showDropdown: false
    };
  },
  methods: {
    toggleDropdown(){
      this.showDropdown = !this.showDropdown;
    },
    hideDropdown(){
      this.showDropdown = false;
    },
    handleClick(path){
      this.hideDropdown();
      if (this.$route.path === path){
        this.$router.go(0); // Refresh the page if the current route is the same
      } else {
        this.$router.push(path);
      }
    }
  }
}
</script>


<style scoped>
.nav-bar {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 60px;
    text-align: left;
    font-size: var(--font-size-xl);
    color: var(--color-black);
    font-family: var(--font-koulen);
    background: #e9e9e9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .nav-title {
    font-size: larger;
  }

  .nav-logo {
    width: 30px;
    height: 30px;
    padding-right: 8px;
    padding-bottom: 3px;
  }

  .nav-left {
    display: flex;
    flex-direction: row;
    padding-left: 32px;
    align-items: center;
  }

  .nav-list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin: auto;
  }
  
  .nav-right {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding-right: 32px;
  }

  img {
    width: 30px;
  }
  
  .nav-dropdown {
    display: none;
  }

  @media screen and (max-width: 768px){
      .nav-dropdown {
        display:inline-block;
      }

      .nav-list {
        display: none;
      }

      button {
        padding: 0px;
        margin-top: 14px;
        margin-right: 8px;
        border: none;
        scale: 90%;
        background-color: transparent;
      }

      button:hover {
        background-color: pink;
        border-radius: 4px;
      }

      .nav-list-dropdown {
        position: absolute;
        background: #e9e9e9;
        width: 100px;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 20px;
        border-radius: 8px;
      }

      .nav-logo {
        display: none;
      }
  }

@media (hover:none) {
  button:hover {
    background-color: transparent;
  }
}
</style>
