//import Vue from 'vue';
//import VueRouter from 'vue-router';
//import { compile } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

//Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component:()=> import('./views/HomePage.vue')
  },
  {
    path: '/about',
    name: 'about',
    component:()=> import('./views/AboutPage.vue')
  },
  {
    path: '/archive',
    name: 'archive',
    component:()=> import('./views/ArchivePage.vue')
  },
  {
    path: '/works',
    name: 'works',
    component:()=> import('./views/WorksPage.vue')
  },
];

// const router = new VueRouter({
//   routes
// });

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
